<template lang="pug">
div.join
  div.view_container
    Header
    div.loading(v-show="loading")
      img(v-bind:src="loadingSrc")
      span Record my video
    div.add_participant_container(v-show="showJoin && !loading" v-on:click="play" )
      div.title  To do your video, let us know who you are
      div.input_container
        input(v-model="name" type="text" placeholder="Enter your name" v-on:click="preventPropagation")
      div.input_container
        input(v-model="email" type="text" placeholder="Enter your email" v-on:keyup="keyEnterToSubmit" v-on:click="preventPropagation")
      br
      button.cta(v-on:click="addParticipant") Next
  div.video_overlay(v-bind:class="{opacity_80: showJoin}")
  div.control_overlay
    button.cta.record_button(v-on:click="showJoin=true" v-show="!showJoin" style="touch-action: manipulation")
      img(:src="recordIconSrc")
      span Record my video
  div.play_button(v-show="showPlayButton" v-on:click="play")
    img(:src="playIconSrc")
  div.embed-container
    video#intro_video(
      v-on:click="play"
      v-bind:class="{blur_video: showPlayButton}"
      width="100%"
      height="100%"
      playsinline
    )
    video#video_cover_blur(playsinline muted)
</template>

<script>
/* global gtag */
import { GET_PROJECT_BY_SLUG_QUERY } from '../graphql/queries'
import { INVITE_PEOPLE_FROM_SLUG_MUTATION } from '../graphql/mutations'
import Header from '../components/Header'
// import config from '../appConfig'

export default {
  name: 'JoinProjectAsParticipant',
  metaInfo() {
    return {
        title: `Make a video with ${this.projectUser.firstName}`,
        meta: [
            { property: 'og:title', content: `Make a video with ${this.projectUser.firstName}`},
            { property: 'og:description', content: `Make a video with ${this.projectUser.firstName} for ${this.projectRecipientName}`},
            {property: 'og:video', content: `${this.projectIntroVideoUrl}`},
            {name: 'robots', content: 'index,follow'}
        ]
    }
  },
  components: {
    Header,
  },
  watch: {
    $route () {
      // react to route changes...
      if (this.$route.params.slug) {
        this.slug = this.$route.params.slug
      }
    },
    projectWithSlug: function() {
      if (this.projectIntroVideoUrl) this.play()
    },
  },
  computed: {
    recordIconSrc () {
      return process.env.BASE_URL + "video_record_icon_white.svg"
    },
    playIconSrc () {
      return process.env.BASE_URL + "play_icon_white.svg"
    },
    loadingSrc () {
      return process.env.BASE_URL + "loading.gif"
    },
    projectUser () {
      return this.projectWithSlug?.user ? this.projectWithSlug?.user : ""
    },
    projectRecipientName () {
      return this.projectWithSlug?.recipientName ? this.projectWithSlug?.recipientName : ""
    },
    projectIntroVideoUrl () {
      return this.projectWithSlug?.introVideoUrl ? this.projectWithSlug?.introVideoUrl : null
    },
  },
  data () {
    return {
      loading: false,
      name: null,
      email: null,
      projectWithSlug: null,
      participant: null,
      slug: null,
      showJoin: false,
      videoMuted: false,
      showPlayButton: false,
    }
  },
  apollo: {
    projectWithSlug () {
      return {
        // gql query
        query: GET_PROJECT_BY_SLUG_QUERY,
        // Static parameters
        variables () {
          return {
            slug: this.slug
          }
        },
        skip () {
          return !this.slug
        },
        fetchPolicy: 'cache-and-network',
      }
    }
  },
  methods: {
    preventPropagation: function(event) {
      event.stopPropagation()
    },
    addParticipant: async function(event) {
      event.stopPropagation()

      this.loading = true
      this.$apollo.mutate({
        mutation: INVITE_PEOPLE_FROM_SLUG_MUTATION,
        variables: {
          projectSlug: this.slug,
          participants: [{
            name: this.name,
            email: this.email
          }]
        },
      }).then((data) => {
        console.log(data)
        const participants = data.data.addParticipantsFromSlug.participants
        const errors = data.data.addParticipantsFromSlug.errors
        this.errorsToast(errors)

        if (participants) {
          this.participant = participants[0]
          this.next()
        }
        this.loading = false

      }).catch((error) => {
        console.error(error)
        this.errorToast(error)
        this.loading = false
      })
    },
    play: async function() {
      this.showJoin = false
      var video = document.getElementById("intro_video")
      const bg_video_cover_blur = document.getElementById("video_cover_blur")

      video.src = this.projectIntroVideoUrl
      bg_video_cover_blur.src = video.src

      video.muted = false // config.isSafarivideo.load()
      bg_video_cover_blur.load()
      video.muted = false
      bg_video_cover_blur.muted = true

      // cover video if its in landscape
      video.style.objectFit = ((video.videoWidth < video.videoHeight) && (window.innerWidth > window.innerHeight)) ? "contain" : "cover"
      video.onloadeddata = () => {
        video.style.objectFit = ((video.videoWidth < video.videoHeight) && (window.innerWidth > window.innerHeight)) ? "contain" : "cover"
      }

      video.addEventListener('ended', () => {
        this.showJoinUI()
      })

      try {
        await video.play()
        bg_video_cover_blur.play()
        this.showPlayButton = false
      } catch(err) {
        video.pause()
        bg_video_cover_blur.pause()
        video.currentTime = 0
        bg_video_cover_blur.currentTime = 0
        this.showPlayButton = true
      }


    },
    showJoinUI: function () {
      this.showJoin = true
    },
    toggleMute: function() {
      var video = document.getElementById("intro_video")
      video.muted = !video.muted
      this.videoMuted = video.muted
    },
    openFullscreen: function(elem) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen()
      } else if (elem.mozRequestFullScreen) { /* Firefox */
        elem.mozRequestFullScreen()
      } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
        elem.webkitRequestFullscreen()
      } else if (elem.msRequestFullscreen) { /* IE/Edge */
        elem.msRequestFullscreen()
      }
    },
    next: function() {
      this.$router.push(
        {name:"AddParticipantVideoContainer",
        params: {
          participant: this.participant,
          slug: this.participant.slug
        }
      })
    },
    keyEnterToSubmit: function (e) {
      if (e.keyCode === 13) {
        this.addParticipant()
      }
    },
  },
  mounted: function() {
    if (this.$route.params.slug) {
      this.slug = this.$route.params.slug
    }
    window.vue = this

    gtag('config', process.env.VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID, {'page_path': '/joined'})
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.join {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  background-color: #000000;
}

.add_participant_container {
  padding: 12% 0;
  height: 76%;
  position: relative;
  z-index: 1000;
}

.title {
  font-family: gibsonsemibold;
  font-size: 32px;
  color: #FFFFFF;
  text-align: left;
}

.control_overlay {
  position: absolute;
  bottom: 10%;
  left: 0px;
  width: 100%;
  text-align: center;
  z-index: 1000;
}

button.record_button {
  z-index: 1000;
}
button.record_button img {
  display: inline-block;
  margin: 0px 14px 2px -14px;
}

.blur_video {
  -o-filter: blur(15px);
  filter: blur(15px);
  object-fit: cover;
  transform: scale(1.06); /* scale up to hide the edge blur */
}

.video_overlay {
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0.0;
  background-color:#000000;
}

.opacity_80 {
  opacity: 0.8;
}

.embed-container {
  --video--width: 1280;
  --video--height: 720;

  position: relative;
  padding-bottom: calc(var(--video--height) / var(--video--width) * 100%);
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index:100;
  top: 0px;
  left: 0px;
}

.embed-container iframe,
.embed-container object,
.embed-container embed,
.embed-container video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

video#video_cover_blur {
  -o-filter: blur(15px);
  filter: blur(15px);
  object-fit: cover;
  transform: scale(1.06); /* scale up to hide the edge blur */
  z-index: 7;
}

video#intro_video {
  z-index: 200;
}


.play_button {
  position: fixed;
  z-index: 2000;
  left: 50%;
  top: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  background-color: rgb(0,0,0,0.55);
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.play_button:hover {
  background-color: rgb(0,0,0,0.35);
}

.play_button img {
  margin-left:8px;
  width: 28px;
  height: 32px;
}

.input_container {
  background-color: rgba(255,255,255,0.27);
}

.input_container input {
  background-color: rgba(255,255,255,0);
  font-family: gibsonregular;
  font-size: 30px;
  color: #ffffff !important;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(233, 225, 225, 0.7);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: rgba(255,255,255,0.7);
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: rgba(255,255,255,0.7);
}


</style>
